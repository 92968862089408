import React, { useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { withTheme } from '@rjsf/core';
import { Theme as MuiTheme } from '@rjsf/mui';
import validator from '@rjsf/validator-ajv8';
import { FormProvider, useForm } from 'react-hook-form';

type FormData = Record<string, unknown>;

type DynamicFormProps = {
  formData?: FormData;
  onError?: (errors: unknown[]) => void;
  onSubmit: (state: { formData: FormData }) => void;
  readonly?: boolean;
  schema: Record<string, unknown>;
  uiSchema?: Record<string, unknown>;
};

const StyledForm = styled('div')(({ theme }) => ({
  padding: theme.spacing(3),
}));

const Form = withTheme(MuiTheme);

export function DynamicForm({
  formData,
  onSubmit,
  readonly = false,
  schema,
  uiSchema,
}: DynamicFormProps): React.ReactElement {
  const methods = useForm<FormData>({
    // defaultValues: formData,
  });

  const memoizedForm = useMemo(
    () => (
      <Form
        formData={formData}
        readonly={readonly}
        schema={schema}
        uiSchema={uiSchema}
        validator={validator}
        onSubmit={({ formData: data }): void => onSubmit({ formData: data })}
      />
    ),
    [formData, readonly, schema, uiSchema, onSubmit],
  );

  return (
    <FormProvider {...methods}>
      <StyledForm>{memoizedForm}</StyledForm>
    </FormProvider>
  );
}
